$(function () {
    'use strict';
    $(function () {
        $("#Data_da_compra").datepicker({
            dateFormat: 'dd/mm/yy',
            startDate: '-3d'
        });
    });

    $(function () {
        $("#dateexpiration").datepicker({
            dateFormat: 'dd/mm/yy',
            startDate: '-3d'
        });
    });

    $(function () {
        $("#datepayment").datepicker({
            dateFormat: 'dd/mm/yy',
            startDate: '-3d'
        });
    });


    $(function() {
        $('#upload_file').submit(function(e) {
            e.preventDefault();
            $.ajaxFileUpload({
                url 			:'./docs/',
                secureuri		:false,
                fileElementId	:'userfile',
                dataType		: 'json',
                data			: {
                    'title'				: $('#title').val()
                },
                success	: function (data, status)
                {
                    if(data.status != 'error')
                    {
                        $('#files').html('<p>Reloading files...</p>');
                        refresh_files();
                        $('#title').val('');
                    }
                    alert(data.msg);
                }
            });
            return false;
        });
    });







});